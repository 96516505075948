import React, {Component} from 'react';

export default class AvailableServices extends Component {
    mapServiceName(service) {
        switch(service) {
            case 'RADIO_5G': return 'Instalacja radiowa 5GHz'
            case 'RADIO_60G': return 'Instalacja radiowa 60GHz'
            case 'FIBER': return 'Instalacja światłowodowa'
            default: return ''
        }
    }

    getAvailableServices(data) {
        let services = Array(0);

        data.installation_type.forEach(item => {
            services.push(this.mapServiceName(item));
        })

        return services;
    }

    render() {
        const {data} = this.props;
        const services = this.getAvailableServices(data);

        return (
            <div>
                {services.length ? (
                    <div>
                        <p>Dostępne typy instalacji:</p>
                        <ul className="services-ul">
                            {services.map((item, index) => (
                                <li style={{paddingLeft: '15px'}} key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div/>
                )}
            </div>
        )
    }
}