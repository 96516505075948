import React, {Component} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main";

export default class CheckRange extends Component {
    render() {
        return (
            <>
                <Header/>
                <Main/>
                <Footer/>
            </>
        )
    }
}