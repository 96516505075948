import React, {Component} from 'react';
import SuccessIcon from "./icons/SuccessIcon";

export default class Submitted extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="f-modal-alert">
                            <SuccessIcon/>
                            <div style={{textAlign: 'center', marginTop: '25px', fontSize: '18px'}}>
                                <p>Dziękujemy za wypełnienie formularza.</p>
                                <p>Skontaktujemy się, gdy tylko to będzie możliwe.</p>

                                <p className="check-other-address" onClick={() => window.location.reload()}>Sprawdź inny adres</p>

                                <p className="contact">Biuro obsługi klienta<br/> <a className="contact-phone" href="tel:+48525511333"><svg height="5.09mm" viewBox="0 0 14.37 14.44" width="5.07mm"
                                                                                                                  xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="m2.91 9.6a15.36 15.36 0 0 0 5.1 4 8 8 0 0 0 2.85.84h.2a2.35 2.35 0 0 0 1.83-.79 8.21 8.21 0 0 1 .58-.6l.42-.42a1.45 1.45 0 0 0 .11-2l-.11-.11-1.8-1.8a1.47 1.47 0 0 0 -1.09-.54 1.52 1.52 0 0 0 -1 .48l-1.09 1.08-.3-.16-.33-.18a11.34 11.34 0 0 1 -2.71-2.46 6.36 6.36 0 0 1 -.91-1.46c.28-.26.54-.53.8-.79l.27-.27a1.41 1.41 0 0 0 .16-2l-.16-.16-.89-.89-.3-.31c-.2-.2-.4-.41-.61-.6a1.47 1.47 0 0 0 -1-.46 1.57 1.57 0 0 0 -1.11.46l-1.12 1.12a2.3 2.3 0 0 0 -.7 1.48 5.61 5.61 0 0 0 .43 2.39 14 14 0 0 0 2.48 4.15zm-2.17-6.48a1.58 1.58 0 0 1 .48-1l1.11-1.12a.82.82 0 0 1 .56-.27.82.82 0 0 1 .54.27c.2.19.38.38.59.59l.31.32.89.89a.7.7 0 0 1 .14 1l-.14.14-.28.28c-.28.28-.54.55-.82.8a.6.6 0 0 0 -.12.65 7.15 7.15 0 0 0 1 1.73 11.82 11.82 0 0 0 2.89 2.6 3 3 0 0 0 .4.21l.33.18a.57.57 0 0 0 .73-.13l1.15-1.06a.79.79 0 0 1 .55-.27.73.73 0 0 1 .53.27l1.8 1.8a.72.72 0 0 1 .11 1 .63.63 0 0 1 -.12.11 5 5 0 0 1 -.4.4c-.22.21-.43.43-.62.65a1.63 1.63 0 0 1 -1.29.55h-.15a7.07 7.07 0 0 1 -2.58-.77 14.61 14.61 0 0 1 -4.85-3.8 13.23 13.23 0 0 1 -2.36-3.93 4.85 4.85 0 0 1 -.38-2.09z"
                                        fill="#333" transform="translate(-.01)"></path>
                                </svg> +48 52 55 11 333</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}