import * as yup from 'yup';

export const contactValues = {
    first_name: '',
    last_name: '',
    phone: '',
    email: ''
}

export function getContactValidationSchema() {
    let validationObj = {
        first_name: yup.string().nullable().required('Wpisz imię'),
        email: yup
            .string()
            .nullable()
            .email('Wprowadź poprawny adres e-mail')
            .test('', 'Podaj nr telefonu lub e-mail', function (value) {
                const {phone} = this.parent;
                if (!phone) return value != null
                return true
            }),
        phone: yup
            .string()
            .nullable()
            .matches(/^[0-9]{9}$/, 'Nr telefonu musi składać się z 9 cyfr')
            .test('', 'Podaj nr telefonu lub e-mail', function (value) {
                const {email} = this.parent;
                if (!email) return value != null
                return true
            })
    }

    return yup.object().shape(validationObj);
}