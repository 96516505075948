import React from 'react';
import CheckRange from "./app/pages/CheckRange";

function App() {
  return (
    <CheckRange/>
  );
}

export default App;
