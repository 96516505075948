import {object, string} from "yup";

export const rangeValues = {
    city: '',
    street: '',
    build_number: '',
    home_number: ''
}

export const rangeValidationSchema = object().shape({
    city: string().nullable().required('Wpisz miejscowość'),
    build_number: string().nullable().required('Wpisz numer budynku')
})