import React, {Component} from 'react';
import RangeForm from "./RangeForm";
import AfterCheck from "./AfterCheck";

export default class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchAddress: null,
            rangeChecked: false,
            rangeCheckResponse: null,
        }
    }

    setRangeChecked = (address, response) => {
        let available = true;
        if (!response.installation_type.length) {
            available = false;
        }
        this.setState({rangeChecked: true, rangeCheckResponse: response, searchAddress: address, available: available});
    }

    render() {
        return (
            <main className="main-content">
                {this.state.rangeChecked ? (
                    <AfterCheck data={this.state.rangeCheckResponse}
                                searchAddress={this.state.searchAddress}
                                available={this.state.available}/>
                ) : (
                    <RangeForm rangeChecked={this.setRangeChecked}/>
                )}
            </main>
        )
    }
}