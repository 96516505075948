import React, {Component} from 'react';
import {Field, Form, Formik} from "formik";
import {rangeValidationSchema, rangeValues} from "../forms/range.values";
import {FormikAutocomplete} from "../forms/components/FormikAutocomplete";
import {FormikInputText} from "../forms/components/FormikInputText";
import axios from "axios";
import qs from "qs";
import {CUSTOMER_SERVICE_URL} from "../_config/config";

const URL = CUSTOMER_SERVICE_URL + "api/v1/";
const HP_URL = CUSTOMER_SERVICE_URL + "api/v1/new_house_point_entry/"

export function debounce(delay, fn) {
    let timerId;
    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = null;
        }, delay);
    }
}

export default class RangeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rangeCheckResponse: null,
            noStreet: false,
            teryt: {
                city: Array(0),
                street: Array(0)
            }
        }

        this.handleInputChangeTerytCity = debounce(300, this.handleInputChangeTerytCity)
        this.handleInputChangeTerytStreet = debounce(300, this.handleInputChangeTerytStreet)
    }

    fetchTeryt = (search) => {
        axios.get(URL + `hp/?${qs.stringify(search)}`)
            .then(json => {
                this.setState({teryt: json.data.data})
            })
            .catch(err => console.error('Bląd'));
    };

    checkTeryt(search) {
        return axios.get(HP_URL, {params: {...search}})
            .then(json => json.data.data);
    }

    cityChanged(value, initialValues) {
        const val = initialValues;

        if (value !== val.city.name + ' ' + val.city.terc) {
            val.street = {
                sym: null,
                name: '',
                sym_ul: ''
            }
            val.build_number = '';
            val.home_number = '';
        }
    }

    handleChangeTerytCity = (value) => {
        if (!value) return;
        this.handleInputChangeTerytCity(value);
    }

    handleInputChangeTerytCity = (value) => {
        if (value.length > 2) {
            let searchValue = value;
            let i = value.indexOf(" woj.");

            if (i > 0) {
                searchValue = value.substr(0, i);
            }

            this.fetchTeryt({
                log: false,
                city: searchValue
            })
        }

        return value;
    }

    handleChangeTerytStreet = (formik, value) => {
        if (!value) return;
        this.handleInputChangeTerytStreet(formik, value);
    }

    handleInputChangeTerytStreet = (formik, newValue) => {
        if (newValue.length > 2 && formik.values.city) {
            this.fetchTeryt({
                sym: formik.values.city.sym,
                street: newValue
            });
        }
        return newValue;
    };

    handleCheckbox = (formik, event) => {
        this.setState({noStreet: event.target.checked})
        if (formik.values.street && formik.values.street.sym_ul) {
            formik.setFieldValue('street', '');
        }
    }

    prepareData(values) {
        if (values.street && values.street.sym_ul) {
            return {
                sym: values.city.sym,
                sym_ul: values.street.sym_ul,
                build_number: values.build_number,
                home_number: values.home_number,
            }
        } else {
            return {
                sym: values.city.sym,
                build_number: values.build_number,
                home_number: values.home_number
            }
        }
    }

    checkRange(values) {
        this.checkTeryt(this.prepareData(values)).then(data => {
            this.props.rangeChecked(values, data);
        })
    }

    render() {
        return (
            <div className="container">
                <div className="row check-range-content">
                    <div className="col-md-6 check-range-header">
                        <p style={{paddingTop: '60px'}}>Sprawdź<br/> dostępność usług<br/>Internetu i Telewizji<br/> dla Twojego adresu</p>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <Formik
                                enableReinitialize
                                initialValues={rangeValues}
                                validationSchema={rangeValidationSchema}
                                onSubmit={(values, actions) => {
                                    setTimeout(() => {
                                        this.checkRange(values);
                                        actions.setSubmitting(false);
                                    }, 1000);
                                }}
                            >{(props) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Field
                                                type="select"
                                                name="city"
                                                textFieldProps={{
                                                    label: 'Miasto'
                                                }}
                                                matchFrom='start'
                                                style={{marginBottom: '15px'}}
                                                component={FormikAutocomplete}
                                                options={this.state.teryt.city}
                                                onInputChange={(event, value) => {
                                                    if (props.values.city) {
                                                        this.cityChanged(value, props.values);
                                                    }
                                                    this.handleChangeTerytCity(value)
                                                }}
                                                getOptionLabel={(option) => option.name ? option.name + ' ' + option.terc : ''}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label>
                                                <input type="checkbox"
                                                       className="filled-in"
                                                       onChange={(event) => this.handleCheckbox(props, event)}
                                                       defaultChecked={this.state.noStreet}/>
                                                <span>W mojej miejscowości nie ma ulicy</span>
                                            </label>
                                        </div>
                                        <div className="col-md-12">
                                            <Field
                                                type="select"
                                                name="street"
                                                textFieldProps={{
                                                    label: 'Ulica'
                                                }}
                                                component={FormikAutocomplete}
                                                options={this.state.teryt.street}
                                                onInputChange={(event, value) => this.handleChangeTerytStreet(props, value)}
                                                getOptionLabel={(option) => option.name ? option.name : ''}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                disabled={this.state.noStreet}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-field">
                                                <Field name="build_number" component={FormikInputText} label="Nr budynku"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-field">
                                                <Field name="home_number" component={FormikInputText} label="Nr lokalu"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <button type="submit"
                                                    className="btn btn-primary rfc-button">Sprawdź
                                                zasięg
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}