import React, {Component} from 'react';

export default class ProvidedAddress extends Component {
    render() {
        const { address } = this.props;

        return (
            <div style={{fontWeight: '600', paddingTop: '15px', paddingBottom: '25px'}}>
                <div>{address.city.name}, {address.city.terc}</div>
                {address.street && address.street.sym ? (
                    <div>{address.street.name} {address.build_number}{address.home_number ? `/${address.home_number}` : ''}</div>
                ) : (
                    <div>numer budynku: {address.build_number}{address.home_number ? `, numer lokalu: ${address.home_number}` : ''}</div>
                )}
            </div>
        )
    }
}