import React, {Component} from 'react';
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {
    contactValues,
    getContactValidationSchema
} from "../forms/contact.values";
import {FormikInputText} from "../forms/components/FormikInputText";
import {CUSTOMER_SERVICE_URL} from "../_config/config";
import {CONTACT_TYPE_EMAIL, CONTACT_TYPE_PHONE, DEFAULT_TERYT_SYM_UL, SOURCE} from "../_config/constants";

const HP_URL = CUSTOMER_SERVICE_URL + "api/v1/new_house_point_entry/"

export default class ContactForm extends Component {

    constructor(props) {
        super(props);

        const { available } = this.props;

        if (available) {
            this.state = {
                phone: false,
                email: false
            }
        } else {
            this.state = {
                phone: true,
                email: true
            }
        }
    }

    prepareData(values, address) {
        const address_info = {
            sym: address.city.sym,
            sym_ul: address.street ? (address.street.sym_ul && address.street.sym_ul) : DEFAULT_TERYT_SYM_UL,
            build_number: address.build_number,
            home_number: address.home_number ? address.home_number : "1"
        }

        const contact_type = this.state.phone ? CONTACT_TYPE_PHONE : CONTACT_TYPE_EMAIL

        return {
            ...values,
            ...address_info,
            contact_type: contact_type,
            source: SOURCE,
            note: '***Dodano z zasieg.rfc.pl***'
        }
    }

    newHpEntry(values) {
        return axios.post(HP_URL, values)
    }

    render() {
        const { available, address } = this.props;

        return (
            <Formik
                enableReinitialize
                initialValues={contactValues}
                validationSchema={getContactValidationSchema()}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        this.newHpEntry(this.prepareData(values, address))
                            .then(() => this.props.setSubmitted())
                            .catch(err => console.log(err))
                    }, 1000);
                }}
            >{(props) => (
                <Form>
                    <div className="row">
                        <div className="col-md-6">
                            <Field name="first_name" component={FormikInputText} label="Imię"/>
                        </div>
                        <div className="col-md-6">
                            <Field name="last_name" component={FormikInputText} label="Nazwisko"/>
                        </div>
                        <div className="col-md-6">
                            <Field name="phone" component={FormikInputText} label="Nr telefonu"/>
                        </div>
                        <div className="col-md-6">
                            <Field name="email" component={FormikInputText} label="Adres e-mail"/>
                        </div>
                    </div>
                    {available ? (
                        <div className="row">
                            <div className="col-md-6">
                                <button type="submit"
                                        disabled={props.isSubmitting}
                                        onClick={() => this.setState({phone: true, email: false})}
                                        className="btn btn-primary rfc-button">Zamów rozmowę</button>
                            </div>
                            <div className="col-md-6">
                                <button type="submit"
                                        onClick={() => this.setState({phone: false, email: true})}
                                        disabled={props.isSubmitting}
                                        className="btn btn-primary rfc-button">Zamów ofertę</button>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-12">
                                <button type="submit"
                                        disabled={props.isSubmitting}
                                        className="btn btn-primary rfc-button">Wyślij</button>
                            </div>
                        </div>
                    )}
                </Form>
            )}
            </Formik>
        )
    }

}