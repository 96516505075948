import React from 'react'
import {fieldToTextField} from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";
import * as _ from "lodash";

const checkError = (errors, fieldName) => {
    let keys = fieldName.split('.');
    let value = errors;

    keys.forEach(item => {
        if (item in value) {
            value = value[item];
        }
    })
    if (typeof value === 'string') {
        return value
    }
    return ''
}

export function createFilterOptions(config = {}) {
    const {
        ignoreAccents = true,
        ignoreCase = true,
        limit,
        matchFrom = 'any',
        stringify,
        trim = false,
    } = config;

    return (options, { inputValue, getOptionLabel }) => {
        let input = trim ? inputValue.trim() : inputValue;
        if (ignoreCase) {
            input = input.toLowerCase();
        }
        if (ignoreAccents) {
            input = _.deburr(input);
        }

        const filteredOptions = options.filter((option) => {
            let candidate = (stringify || getOptionLabel)(option);
            if (ignoreCase) {
                candidate = candidate.toLowerCase();
            }
            if (ignoreAccents) {
                candidate = _.deburr(candidate);
            }

            return matchFrom === 'start' ? candidate.indexOf(input) === 0 : candidate.indexOf(input) > -1;
        });

        return typeof limit === 'number' ? filteredOptions.slice(0, limit) : filteredOptions;
    };
}

export const FormikAutocomplete = ({textFieldProps, matchFrom = 'any', ...props}) => {
    const {form: {setTouched, setFieldValue, errors}} = props;
    const {error, helperText, ...field} = fieldToTextField(props);
    const {name} = field;

    const filterOptions = createFilterOptions({
        matchFrom: matchFrom,
    });

    return (
        <Autocomplete
            {...props}
            {...field}
            filterOptions={filterOptions}
            onChange={(_, value) => setFieldValue(name, value)}
            onBlur={() => setTouched({[name]: true})}
            noOptionsText="Brak opcji"
            renderInput={props => (
                <TextField {...props} {...textFieldProps}
                           helperText={checkError(errors, name)}
                           error={Boolean(checkError(errors, name))}/>
            )}
        />
    )
}