import React, {Component} from 'react';

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer-copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-xlg-10 offset-xlg-1 col-lg-11 offset-lg-1">
                                <div className="row row-border">
                                    <div className="col-sm-6 p-0">
                                        <a className="footer-a" href="https://rfc.pl/polityka-prywatnosci/" title="Polityka prywatności">Polityka
                                            prywatności</a> © 2004 - 2020 RFC Internet i Telewizja
                                    </div>
                                    <div className="col-sm-6 p-0">
                                        <div className="order-2">
                                            projekt i wykonanie: <a className="footer-a" target="_blank"
                                                                    rel="nofollow noopener noreferrer"
                                                                    href="http://rfc.pl"
                                                                    title="Projekt i wykonanie www.rfc.pl">
                                            RFC.pl
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}