import React, {Component} from 'react';
import ContactForm from "./ContactForm";
import ProvidedAddress from "./ProvidedAddress";
import AvailableServices from "./AvailableServices";
import Submitted from "./Submitted";

export default class AfterCheck extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false
        }
    }

    setSubmitted = () => {
        this.setState({submitted: true})
    }


    render() {
        const {data, searchAddress, available} = this.props;

        if (this.state.submitted) {
            return <Submitted/>
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            {data && (
                                <>
                                    <div className="col-md-12" style={{textAlign: 'center', fontSize: '70px'}}>
                                        {available ? (
                                            <i className="fa fa-smile-o" style={{color: 'green'}}/>
                                        ) : (
                                            <i className="fa fa-frown-o" style={{color: '#d9000d'}}/>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        {available ? (
                                            <>
                                                <div>
                                                    <p>Jest możliwość podłączenia Internetu pod wskazanym adresem.</p>
                                                    <ProvidedAddress address={searchAddress}/>
                                                    <AvailableServices data={data}/>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <p>Na ten moment nie ma możliwości technicznych pod wskazanym adresem.</p>
                                                <ProvidedAddress address={searchAddress}/>
                                                <p>Wypełnij formularz kontaktowy, aby otrzymać informacje, gdy tylko pojawi się taka możliwość.</p>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <ContactForm available={available}
                                         address={searchAddress}
                                         setSubmitted={this.setSubmitted}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}